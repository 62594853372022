import React from "react";
import Navbar from "../Navbar";
import Actuality from "../Actuality";

export default function Actualities() {
  return (
    <>
      <Navbar />
      <div className="mt-28 md:mt-36 mb-12 mx-6 md:mx-12">
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          <Actuality
            title="Tomáš Jandík: Otevírá se okno pro investice do nemovitostí. Než ECB začne snižovat sazby"
            content="Kdo má aktuálně kapitál, dokáže zajistit lépe dlouhodobé příležitosti. Až klesnou sazby ECB, hodnota nemovitostí opět poroste, říká Tomáš Jandík, místopředseda představenstva REICO Investiční společnost České spořitelny."
            img="https://www.newstream.cz/uploads/article/660x464_fit/Jandik.png"
            url="https://www.newstream.cz/reality/tomas-jandik-otevira-se-okno-pro-investice-do-nemovitosti-nez-zacne-snizovat-sazby-ecb"
          />
          <Actuality
            title="Češi mají novou možnost, jak ušetřit. Zapojením baterie do stabilizace sítě"
            content="Jak ušetřit za elektřinu? Vedle klasických způsobů, jako je změna dodavatele, fixace ceny či nákup úspornějších spotřebičů a svítidel, přibyl ještě jeden nový. Jedná se o nabídku flexibility ve spotřebě elektřiny. Jako první ji svým zákazníkům nabízí společnost Delta Green. Po prvních dvou měsících od spuštění služby lze říct, že zapojené domácnosti snížily svůj účet za elektřinu o 100 až 600 korun měsíčně."
            img="https://ekonomickydenik.cz/app/uploads/2024/12/the-roof-of-the-3257327-1280.jpg"
            url="https://ekonomickydenik.cz/cesi-maji-novou-moznost-jak-usetrit-zapojenim-baterie-do-stabilizace-site/"
          />
                    <Actuality
            title="Švédsko se zlobí na Němce: Odstavili jádro a my máme rekordně vysoké ceny energie"
            content="Německé rozhodnutí odstavit jaderné elektrárny má za následek dramatický nárůst cen elektřiny, který zasáhl nejen Německo, ale i jeho sousedy. Švédská vicepremiérka Ebba Buschová považuje tento krok za klíčovou příčinu současných problémů a tvrdí, že ohrožuje evropskou ekonomiku i konkurenceschopnost, informoval deník Bild."
            img="https://www.echo24.cz/img/675be8cb534406cea96cf997/1910/1000?_sig=uMbif-LNgVqcezzxe_Iz6gTyftopBb3505USCzH45sM"
            url="https://www.echo24.cz/a/H7zcu/zpravy-svet-rekorni-ceny-energie-ve-svedsku"
          />
        </div>
      </div>
    </>
  );
}
