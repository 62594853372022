import React from "react";
import { useTranslation } from "react-i18next";
import OneProject from "./OneProject";

export default function AllProjects() {
  const { t } = useTranslation();
  return (
    <>
      <OneProject
        name="Uničov"
        content={t("projects.project1")}
        photoFolder="project1"
        photoAmount={2}
      />
      <OneProject
        name="Resort Chořelice"
        content={t("projects.project2")}
        photoFolder="project2"
        photoAmount={8}
      />
      <OneProject
        name="Šternberk"
        content={t("projects.project3")}
        photoFolder="project3"
        photoAmount={3}
      />
      <OneProject
        name="Costa Blanca"
        content={t("projects.project4")}
        photoFolder="project4"
        photoAmount={8}
      />
      <OneProject
        name="Haňovice"
        content={t("projects.project5")}
        photoFolder="project5"
        photoAmount={7}
      />
    </>
  );
}
